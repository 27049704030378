<template>
	<div>
    <van-field
      v-model="fieldValue"
      name="jgbm"
      :label="label"
      :label-width="labelWidth"
      :required="required"
      :is-link="!disabled"
      :placeholder="placeholder"
      readonly
      :inputAlign="inputAlign"
      @click="show = !show"
      :rules="[{ required: true, message: '请选择组织机构' }]"/>
    <van-popup v-model="show" position="bottom" v-if="!disabled">
      <van-cascader
          v-model="cascaderValue"
          title="请选择组织机构"
          :options="options"
          :field-names="fieldNames"
          @close="close"
          @finish="onFinish"
      />
    </van-popup>
	</div>

</template>

<script>
	import {
		orgManageService_getJgzzBySjzzbm
	} from '@/api/psdmsqxgl/services/OrgManageServiceAPI'

	export default {
		name: "ZzjgSelect",
    props: {
			value: {
				default: '1R4'
			},
      required:{
			  default:false
      },
      sjzzbm:{
        default: ''
      },
      showZz:{
			  default:false
      },
			placeholder: {
				type: String,
				default: '请选择组织机构'
			},
      disabled:{
        default:false
      },
      label:{
        type: String,
			  default:'组织机构'
      },
      labelWidth:{
        type: [String,Number],
        default:'90'
      },
      inputAlign:{
        type: String,
        default:'left'
      },
		},
		data() {
			return {
        show: false,//Popup是否弹出
        fieldNames: {
          text: 'label',
          value: 'id',
          children: 'children',
        },
        fieldValue: null,//初始选中数据
        cascaderValue: '',
        options:[],
			};
		},
		methods: {
      filterTreeData(list) {
        let arr = [];
        for(let i = 0; i < list.length; i++) {
          const item = list[i];
          if (this.value && item.zzbm == this.cascaderValue) {
            this.fieldValue = item.label;
          }
          item.children = item.children && item.children.length ? this.filterTreeData(item.children) : null;
          arr.push(item);
        }
        return arr;
      },
      onFinish({value, selectedOptions }) {
        this.show = false;
        this.fieldValue = selectedOptions[selectedOptions.length-1]['label'];
        this.cascaderValue = value;
        let arr = selectedOptions.map((option) => option.id);
        this.$emit('input', arr);
        this.$emit('confirm', arr, selectedOptions)
      },
      close () {//取消
        this.show = !this.show
        this.$emit('cancel')
      },
			getJgzzList() {
				orgManageService_getJgzzBySjzzbm(this.sjzzbm, this.showZz).then(reponse => {
          this.options = reponse.content;
          this.filterTreeData(this.options);
				});
			}
		},
    watch: {
      value: {
        handler (newVal) {//监听变化初始赋值
          this.cascaderValue = newVal && newVal.length ? newVal[newVal.length - 1] : '';
          this.filterTreeData(this.options);
        },
        immediate: true
      },
      sjzzbm: {
        handler (newVal,oldVal) {
          this.fieldValue = '';
          if(newVal && newVal != oldVal){
            this.getJgzzList();
          }
        },
        immediate: true
      },
    },
		mounted() {
			this.getJgzzList();
		}
	}
</script>

<style>
</style>
